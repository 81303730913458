<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-08 16:14:24
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-10-09 10:42:52
 * @FilePath: \official_website\src\mobile\pages\science\Science.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="science">
    <Header></Header>
    <div class="science-top">
      <p class="title animate__animated animate__fadeInDown">学术园地</p>
    </div>
    <div class="science-box">
      <van-tabs
        v-model="active"
        color="#0b847b"
        line-width="50px"
        line-height="5px"
        background="#f8f8f8"
      >
        <van-tab title="文献解读">
          <Decipher></Decipher>
        </van-tab>
        <van-tab title="吉博士">
          <Doctor></Doctor>
        </van-tab>
      </van-tabs>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import Decipher from "./components/Decipher.vue";
import Doctor from "./components/Doctor.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    Decipher,
    Doctor,
  },
  data() {
    return {
      active: 0, // 当前tab
      newsList: [], // 分页新闻列表
    };
  },
  created() {
    this.getNewsList();
    this.getNewsCounts();
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });

    // window.addEventListener("scroll", this.handleScroll, true);
  },
  watch: {
    currentPage: {
      handler(oldValue, newValue) {
        console.log(newValue);
        if (newValue + 1 >= Math.ceil(this.count / 10)) {
          this.moreFlag = false;
        }
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 分页获取文章列表
    getNewsList(page = 1) {
      api
        .getNewsByPage(page)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let result = res.data.models;
            result.forEach((item) => {
              if (item.createTime) {
                item.createTime = item.createTime.split(" ")[0];
              } else {
                item.createTime = "";
              }
            });

            if (page > 1) {
              this.newsList = this.newsList.concat(result);
            } else {
              this.newsList = result;
            }

            // console.log(this.newsList);
            // this.$nextTick(() => {
            //   new this.$wow.WOW({
            //     boxClass: "wow", // 动画元件css类（默认为wow）
            //     animateClass: "animate__animated", //动画css类（默认为animated）
            //     offset: 0, //到元素距离触发动画（当默认为0）
            //     mobile: true, //在移动设备上触发动画（默认为true）
            //     live: true, //对异步加载的内容进行操作（默认为true）
            //   }).init();
            // });
          }
        })
        .catch((error) => {});
    },
    // 获取文章总条数
    getNewsCounts() {
      api
        .getNewsCount()
        .then((res) => {
          if (res.data.code == 200) {
            this.count = res.data.models[0];
          }
        })
        .catch((error) => {});
    },
    tapArticle(id) {
      this.$router.push({
        path: `/MediumDetail/${id}`,
      });
    },
    knowMore() {
      if (this.currentPage < Math.ceil(this.count / 10)) {
        this.currentPage++;
        this.getNewsList(this.currentPage);
      }
    },
    // 回到顶部
    backTop() {
      this.$nextTick(() => {
        document.getElementById("medium").scrollTop = 0;
      });
    },
    handleScroll(env) {
      this.$nextTick(() => {
        let scrollTop = document.getElementById("medium").scrollTop;
        console.log(scrollTop);
        if (scrollTop > 200) {
          this.backTopFlag = true;
        } else {
          this.backTopFlag = false;
        }
      });
    },
  },
};
</script>
    
<style lang="scss">
#science {
  padding-top: 3.75rem;
  .science-top {
    position: relative;
    width: 100%;
    height: 12rem;
    background-image: url("../../../assets/image/science/science.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-bottom: 1rem;
    .title {
      position: absolute;
      left: 2rem;
      top: 35%;
      transform: translateY(-50%);
      color: #fff;
      font-size: 1.5rem;
      font-family: "SourceHanSansSC-Medium";
    }
  }
  .science-box {
    .van-tab {
      font-size: 1rem;
      font-family: "SourceHanSansSC-Medium";
    }

    .backTop {
      width: 2.25px;
      height: 2.25px;
      background-color: #666;
      border-radius: 50%;
      opacity: 0.8;
      position: fixed;
      right: 1rem;
      bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 1.25rem;
        color: #fff;
      }
    }
  }

  .footer {
    background: #f5f5f5;
  }
}
</style>